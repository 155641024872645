<template>
  <v-container fluid>
    <v-card class="mb-3 py-0" v-if="filter">
      <v-card-actions class="py-0 px-5">
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field v-model="search" prepend-icon="fa-search" label="Meklēt" single-line clearable/>
          </v-col>
          <v-col cols="12" md="3">
            <v-menu
              ref="menu1"
              v-model="calendarDialog"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  label="Datums"
                  persistent-hint
                  prepend-icon="fa-calendar"
                  v-bind="attrs"
                  v-on="on"
                  append-icon="fa-search"
                  @click:append="filterDateSearch"
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dates"
                no-title
                range
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-card>
      <v-toolbar dense flat>
        <v-toolbar-title>Logs</v-toolbar-title>
        <v-spacer/>
        <v-btn icon color="primary" @click="filter = !filter">
          <v-icon>fa-sliders-h</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="logs"
        item-key="item"
        :loading="loading"
        loading-text="Notiek ielāde... Lūdzu, uzgaidiet"
        :search="search"
      />
    </v-card>
  </v-container>
</template>

<script>
import axios from '@/axios'

export default {
  name: 'LogIndex',
  data: () => ({
    filter: false,
    loading: true,
    calendarDialog: false,
    search: '',
    dates: [],
    logs: []
  }),
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    },
    headers () {
      return [
        {
          text: 'Model',
          value: 'type'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Action',
          value: 'action'
        },
        {
          text: 'User',
          value: 'user'
        },
        {
          text: 'Created At',
          value: 'created_at'
        }
      ]
    }
  },
  methods: {
    fetchData () {
      axios.get('logs').then(response => {
        this.logs = response.data
        this.loading = false
      })
    },
    filterDateSearch () {
      this.loading = true
      axios.post('logs/', {
        dates: this.dates
      }).then(response => {
        this.logs = response.data
        this.loading = false
      })
    }
  },
  created () {
    this.fetchData()
  }
}
</script>

<style scoped>

</style>
